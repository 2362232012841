import { ProcedureType } from '@app/features/procedure-order/shared/procedure-order.type';

export const newEntityId = -1;

export enum ProblemStatus {
  CURRENT = 1,
  RESOLVED = 2,
  MISTAKEN = 3,
  UNCONFIRMED = 4,
  SUSPECTED = 5,
}

export interface ProblemCodeExtension {
  id: number;
  description: string;
  character?: string;
}

export interface ProblemCode {
  id: number;
  detailDescription: string;
  code?: string;
  label?: string;
  extensions?: ProblemCodeExtension[];
}

export interface ProblemCodeLocation {
  id: number;
  name: string;
  extensions?: ProblemCodeExtension[];
}

export interface ProblemAutoCreationProperties {
  intervalValue: number;
  intervalType: string;
}

export interface ProblemAutoCreation {
  basicFollowUpOrder?: ProblemAutoCreationProperties;
  visitFollowUpOrder?: ProblemAutoCreationProperties;
}

export interface ProblemType {
  id: number;
  clinicalDescription: string;
  clinicalAbbreviation: string; // Set with results from elastic search
  layDescription: string;
  importance: number;
  locations: ProblemCodeLocation[];
  decisionSupport: string;
  autoCreations: ProblemAutoCreation[];
  tags: string[]; // Set with results from elastic search
  label?: string; // Custom label for the autocomplete component
  isCustom?: boolean;
  commonProcedureTypes?: ProcedureType[];
}

export interface ProblemActions {
  create: {
    createdFollowUpOrder?: boolean;
  };
  delete: {
    allowed: boolean;
    showWarning: boolean;
    reason: string;
  };
}

export interface ProblemAutoCreationFlags {
  basicFollowUpOrder?: boolean;
}

export interface ProblemHistoryEditedBy {
  id: number;
  name: string;
}

export interface ProblemHistoryType {
  clinicalDescription: string;
  id: number;
  importance: number;
}

export interface ProblemHistoryItem {
  aAndP: string;
  briefComment: string;
  createdAt: string;
  editedBy: ProblemHistoryEditedBy;
  id: number;
  onset: string;
  problemCodeLocation?: ProblemCodeLocation;
  problemType: ProblemHistoryType;
  resolution: string;
  status: ProblemStatus;
}

export interface ProblemHistory {
  items: ProblemHistoryItem[];
  total: number;
  problemId: number;
}

export interface ProblemCustomCodeSelection {
  code: string;
  detailDescription: string;
  id: string;
  label?: string;
  extensions?: ProblemCodeExtension[];
}

export interface ProblemAutoCreationFlags {
  basicFollowUpOrder?: boolean;
  visitFollowUpOrder?: boolean;
}

export interface Problem {
  id: number;
  patientId: number;
  problemHistoryId: number;
  status: ProblemStatus;
  active: boolean;
  unconfirmed: boolean;
  suspected: boolean;
  code: string;
  briefComment: string;
  onset: string;
  resolution: string;
  summary: string;
  aAndP: string;
  aAndPDisplayText: string;
  editedBy: string;
  updatedAt: string;
  createdAt: string;
  originatingObject: any;
  historyStartDate: string;
  problemType: ProblemType;
  problemCodeLocation: ProblemCodeLocation;
  useCustomProblemType: boolean;
  includedInMedicalHistory: boolean;
  actions: ProblemActions;
  customProblemTypeCode: string;
  customProblemTypeCodeDescription: string;
  customProblemTypeDescription: string;
  customProblemCodeSelection: ProblemCustomCodeSelection;
  notificationText: string;
  autoCreationsFlags: ProblemAutoCreationFlags;
  isMinorChange: boolean;
  history: ProblemHistory;
}

export interface ProblemHistoryEditedBy {
  id: number;
  name: string;
}

export interface ProblemHistoryType {
  clinicalDescription: string;
  id: number;
  importance: number;
}

export interface ProblemHistoryItem {
  aAndP: string;
  briefComment: string;
  createdAt: string;
  editedBy: ProblemHistoryEditedBy;
  id: number;
  onset: string;
  problemCodeLocation?: ProblemCodeLocation;
  problemType: ProblemHistoryType;
  resolution: string;
}

export interface ProblemHistory {
  items: ProblemHistoryItem[];
  total: number;
  problemId: number;
}

export interface ProblemAutoCreationFlags {
  basicFollowUpOrder?: boolean;
}

export interface ProblemHistoryEditedBy {
  id: number;
  name: string;
}

export interface ProblemHistoryType {
  clinicalDescription: string;
  id: number;
  importance: number;
}

export interface ProblemHistoryItem {
  aAndP: string;
  briefComment: string;
  createdAt: string;
  editedBy: ProblemHistoryEditedBy;
  id: number;
  onset: string;
  problemCodeLocation?: ProblemCodeLocation;
  problemType: ProblemHistoryType;
  resolution: string;
}

export interface ProblemHistory {
  items: ProblemHistoryItem[];
  total: number;
  problemId: number;
}

export interface ProblemSectionLinkPayload {
  activeProblemHistoryIds: number[];
  resolvedProblemHistoryIds: number[];
  medicalHistory: boolean;
}

/**
 * Form
 */
export interface ProblemForm {
  assessmentAndPlan: string;
  basicFollowUpOrder: boolean;
  visitFollowUpOrder: boolean;
  briefComment: string;
  customProblemDescription: string;
  problemCode: string;
  problemId: number;
  onset: string;
  resolution: string;
  summary: string;
  locationId: number;
  includedInMedicalHistory: boolean;
  isCustom: boolean;
  id?: number;
  addProblemToNote: boolean;
  status?: ProblemStatus;
  confirmSuspectedProblems?: boolean;
}
